#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.report__table {
  text-align: center;
  margin-top: 3%;
  object-fit: contain;
  justify-content: space-between;
}

.report__table > button {
  color: white;
  background-color: black;
}

.report__button {
  justify-content: space-between;
  color: rgb(0, 120, 218);
  background-color: lightblue;
}

.report__comment {
  color: #0004ff;
}

.rep__nav {
  display: flex;
}

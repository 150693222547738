.login__button {
  margin-top: 20px;
  position: right;
  background-color: rgb(158, 158, 158); /* Green */
  border: none;
  color: black;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.login__button:disabled {
  opacity: 0.5;
}

.login__image {
  display: flex;
  position: right;
}

.login__user {
  display: flex;
  position: center;
  margin-top: 15px;
  color: black;
  justify-content: space-between;
}

.login__user > button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  margin-left: 10px;
  align-items: center;
  border: 0.5;
  border-color: grey;
}

.container > h4 {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dashboard {
  flex: 0.4;
  border-right: 1px solid var(--twitter-background);
  min-width: fit-content;
  overflow-y: scroll;
}

.notice__box {
  flex: 0.4;
  border: 2px solid black;
  background-color: white;
  padding-right: 50px;
  padding-top: 30px;
  margin-top: 30px;
  margin-left: 10px;
  width: 95%;
  height: 250px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.notice__head {
  position: flex;
  top: 0;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
  margin-top: -20px;
}

.notice__head > h2 {
  display: inline block;
  font-size: 15px;
  font-weight: 800;
}

.notice__head > h3 {
  display: inline block;
  font-size: 12px;
}

.notice__head > h6 {
  display: inline block;
  color: blue;
  font-size: 10px;
}

.project__tit > h4 {
  margin-top: 0%;
  float: left;
  margin-left: 20px;
}

.project__tit > h6 {
  margin-top: 10%;
  float: left;
  margin-left: 20px;
  font-weight: 200;
}

.project__tit > h5 {
  float: left;
  margin-left: 20px;
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 5%;
}

.project__vid {
  margin-top: 0%;
  object-fit: contain;
}

.project {
  flex: 0.4;
  border-right: 1px solid var(--twitter-background);
  min-width: fit-content;
  overflow-y: scroll;
}

.project__comment {
  flex: 01.4;
  padding-right: 50px;
  padding-top: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 100px;
}

.project_card {
  margin-top: 20px;
  background-color: rgb(224, 238, 255);
}

.back__buttons > Button {
  margin-top: 3%;
  margin-left: 20px;
  margin-bottom: 10px;
  background-color: white;
  color: black;
}

.head__name {
  margin-left: 40px;
}

.navbar__toggle {
  text-align: right;
  color: black;
  font-size: 12px;
  border: 0;
  border-radius: 100%;
  box-sizing: border-box;
}

.nav-link > span {
  position: flex;
  font-size: 16px;
  color: white;
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
  background: black;
}

.parent__div {
  overflow: auto;
}

.center__item > h1 {
  position: absolute;
  color: blue;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.report__header > h2 {
  margin-top: 0px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10%;
}

.report__header > Table {
  object-fit: contain;
}

.report__table {
  text-align: center;
  margin-top: 10%;
  object-fit: contain;
  justify-content: space-between;
}

.report__table > button {
  color: white;
  background-color: black;
}

.report__button {
  justify-content: space-between;
  color: white;
  background-color: black;
}

.cert__header > h2 {
  margin-top: 0px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10%;
}

.payment__header > h2 {
  margin-top: 0px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10%;
}

.payment_table > thead {
  position: relative;
  text-align: center;
  width: 350px;
  height: 150%;
  margin-top: 5px;
  object-fit: contain;
  justify-content: space-between;
}

.payment_table > tbody {
  position: flex;
  text-align: center;
  margin-top: 5px;
  object-fit: contain;
  justify-content: space-between;
}

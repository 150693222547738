.project__title {
  text-align: center;
  margin-top: 0px;
  font-size: 30px;
  font-weight: 700;
  position: overflow;
}

.project__table {
  font-size: 15px;
  margin-top: 20px;
}

.project__button {
  justify-content: space-between;
  color: black;
  background-color: white;
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#project {
  margin-top: 10%;
  margin-left: 10px;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 95%;
}

#project td,
#project th {
  border: 1px solid #ddd;
  padding: 8px;
}

#project tr:nth-child(even) {
  background-color: #f2f2f2;
}

#project tr:hover {
  background-color: #ddd;
}

#project th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}

.header__namedis {
  margin-top: -10px;
  object-fit: contain;
}

.header__namedis > h6 {
  font-size: 18px;
  display: block;
  margin-left: 20px;
  font-weight: 600;
  color: rgb(1, 32, 100);
}

.content__home {
  margin-top: -10px;
}

.student_button {
  background-color: rgb(182, 182, 182);
  color: black;
  border-color: black;
}

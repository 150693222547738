#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.payment__header > h2 {
  margin-top: 0px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10%;
}

.payment__table {
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  object-fit: contain;
  justify-content: space-between;
}

.payment__button {
  display: inline-block;
  text-align: center;
  color: white;
  background-color: lightblue;
}

.header__certmob > h2 {
  overflow: auto;
  height: 100vh;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 50px;
  margin-top: 0px;
  font-weight: 700;
}

.cert__card {
  border: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
  background-color: whitesmoke;
}

.cert__card button {
  cursor: pointer;
  color: blue;
  background-color: lightblue;
}
